import {
  ConversationMessageSentBy
} from "./ConversationMessage/conversation-message.interface";
import {backendApiClient} from "./backendApiClient";
import {DynamoKeyInterface} from "./DynamoKey/dynamo-key.interface";

export async function generateAvatarInitialsHelper(sentBy: ConversationMessageSentBy): Promise<string> {
  const api = await backendApiClient()
  let contactKey: DynamoKeyInterface | undefined = undefined
  // if this is a customer
  if (sentBy.type === "CUSTOMER") {
    // is this an email
    if (sentBy.endpoint.includes("@")) {
      let apiResp = await api.get("/admin/query-dynamo?pk=contact_email")
      for (let i of apiResp.data) {
        if (i.sk === sentBy.endpoint) {
          contactKey = i.contactKey
          break
        }
      }
    } else {
      // this is an SMS Number
      let apiResp = await api.get("/admin/query-dynamo?pk=contact_phone")
      for (let i of apiResp.data) {
        if (i.sk === sentBy.endpoint) {
          contactKey = i.contactKey
          break
        }
      }
    }
  }

  let name: string = "UNKNOWN"

  console.log(`contactKey: `)
  console.log(contactKey)

  if (contactKey) {
    let apiResp = await api.get("/admin/query-dynamo?pk=contact")
    for (let i of apiResp.data) {
      if (i.sk === contactKey.sk) {
        name = i.name
        break
      }
    }
  }

  // if this is a employee
  if (sentBy.type === "EMPLOYEE") {
    let apiResp = await api.get("/admin/query-dynamo?pk=employee")
    for (let i of apiResp.data) {
      if (i.email === sentBy.endpoint) {
        name = i.name
      }
    }
  }
  // try and split by space
  let initialSplit = name.split(" ")
  console.log(`initialSplit: `)
  console.log(initialSplit)
  let ret = ""
  for (let i of initialSplit) {
    ret += i.substring(0, 1)
  }

  if (ret.length < 2) {
    ret = name.substring(0, 2)
  }

  console.log(`ret: ${ret}`)
  return ret
}
