import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Box, Button, Container, Typography, Stack } from "@mui/material";

import { useCognitoUserInfo } from "../hooks";

const PrivateRoute = () => {
  const cognitoUserinfo = useCognitoUserInfo();

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => {})
      .catch(() => {
        Auth.federatedSignIn();
      });
  }, [cognitoUserinfo]);

  return (
    <>
      {cognitoUserinfo ? (
        <Outlet />
      ) : (
        <Box
          sx={{
            bgcolor: "background.paper",
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="sm">
            <Typography
              variant="h5"
              align="center"
              color="text.secondary"
              paragraph
            >
              Redirecting to sign in automatically...
            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              <Button
                variant="contained"
                onClick={() => Auth.federatedSignIn()}
              >
                Sign in
              </Button>
            </Stack>
          </Container>
        </Box>
      )}
    </>
  );
};

export default PrivateRoute;
