import { Box, Grid } from "@mui/material";

import SideBar from "./SideBar";
import ConversationsPanel from "./ConversationsPanel";
import MessagePanel from "./MessagePanel";
import { useEffect, useState } from "react";

const ConversationsPage = () => {
  const [filter, setFilter] = useState<string>();
  const [selectedConversationId, setSelectedConversationId] =
    useState<string>();

  useEffect(() => {
    setSelectedConversationId(undefined);
  }, [filter]);

  return (
    <Box display="flex">
      <SideBar setFilter={setFilter} />
      <Grid container>
        <ConversationsPanel
          filter={filter}
          selectedConversationId={selectedConversationId}
          // setSelectedConversationId={setSelectedConversationId}
        />
        {/*<MessagePanel selectedConversationId={selectedConversationId} />*/}
      </Grid>
    </Box>
  );
};

export default ConversationsPage;
