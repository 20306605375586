import { BrowserRouter, Routes, Route } from "react-router-dom";

import NavBar from "./NavBar";
import PrivateRoute from "./PrivateRoute";
import HomePage from "../core/HomePage";
import ProfilePage from "../core/ProfilePage";
import ContactsPage from "../core/ContactsPage";
import ConversationsPage from "../core/ConversationsPage";
import ConversationDetailsPage
  from "../core/ConversationDetailsPage/ConversationDetailsPage";

const Router = () => {
  return (
    <BrowserRouter>
      <NavBar />
      <Routes>
        {/* unauth / public routes */}
        <Route index element={<HomePage />} />
        {/* auth / private routes */}
        <Route path="/" element={<PrivateRoute />}>
          <Route path="profile" element={<ProfilePage />} />
          <Route path="contacts" element={<ContactsPage />} />
          <Route path="conversations" element={<ConversationsPage />} />
          <Route path="conversations/:conversationId" element={<ConversationDetailsPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
