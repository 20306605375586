import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { CognitoUser } from "@aws-amplify/auth";

import { CognitoUserInfo } from "../types";

const useCognitoUserInfo = () => {
  const [cognitoUserInfo, setCognitoUserInfo] = useState<
    undefined | CognitoUserInfo
  >();

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((cognitoUser: CognitoUser) => {
        if (cognitoUser) {
          setCognitoUserInfo({
            name: `${
              cognitoUser.getSignInUserSession()?.getIdToken().payload
                .given_name
            } ${
              cognitoUser.getSignInUserSession()?.getIdToken().payload
                .family_name
            }`,
            email: cognitoUser.getSignInUserSession()?.getIdToken().payload
              .email,
            picture: cognitoUser.getSignInUserSession()?.getIdToken().payload
              .picture,
          });
        }
      })
      .catch(() => {});
  }, []);

  return cognitoUserInfo;
};

export default useCognitoUserInfo;
