import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  Assignment,
  CheckCircle,
  FilterTiltShift,
  Help,
  Menu,
  VisibilityOff,
} from "@mui/icons-material";
import { styled, Theme, CSSObject } from "@mui/material/styles";

import useAppBarHeight from "../../../hooks/useAppBarHeight";

const openedMixin = (theme: Theme): CSSObject => ({
  width: 240,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const CustomDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: 240,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const menuItemNames = [
  "open",
  "closed",
  "assignedToMe",
  "unassigned",
  "unread",
];

interface SideBarProps {
  setFilter: Dispatch<SetStateAction<string | undefined>>;
}

const SideBar = ({ setFilter }: SideBarProps) => {
  const appBarHeight = useAppBarHeight();
  const [searchParams, setSearchParams] = useSearchParams();
  const [sideBarOpen, setSideBarOpen] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>();

  useEffect(() => {
    if (menuItemNames.includes(searchParams.get("selectedTab") || "")) {
      setSelectedTab(searchParams.get("selectedTab") || "");
      setFilter(searchParams.get("selectedTab") || "");
    } else {
      setSelectedTab("open");
      setFilter("open");
    }
  }, [searchParams, setFilter]);

  return (
    <CustomDrawer variant="permanent" open={sideBarOpen}>
      <DrawerHeader sx={{ paddingTop: `${appBarHeight}px` }}>
        <IconButton onClick={() => setSideBarOpen(!sideBarOpen)}>
          <Menu />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: sideBarOpen ? "initial" : "center",
              px: 2.5,
            }}
            selected={selectedTab === "open"}
            onClick={() => {
              setSearchParams("selectedTab=open", { replace: true });
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: sideBarOpen ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <FilterTiltShift />
            </ListItemIcon>
            <ListItemText
              primary="Open"
              sx={{ opacity: sideBarOpen ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: sideBarOpen ? "initial" : "center",
              px: 2.5,
            }}
            selected={selectedTab === "closed"}
            onClick={() => {
              setSearchParams("selectedTab=closed", { replace: true });
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: sideBarOpen ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <CheckCircle />
            </ListItemIcon>
            <ListItemText
              primary="Closed"
              sx={{ opacity: sideBarOpen ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
      </List>
      {sideBarOpen ? (
        <>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: sideBarOpen ? "initial" : "center",
                px: 2.5,
                opacity: "100 !important",
              }}
              disabled
            >
              <ListItemText
                primary="Filters"
                sx={{ opacity: sideBarOpen ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        </>
      ) : (
        <Divider />
      )}
      <List>
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: sideBarOpen ? "initial" : "center",
              px: 2.5,
            }}
            selected={selectedTab === "assignedToMe"}
            onClick={() => {
              setSearchParams("selectedTab=assignedToMe", { replace: true });
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: sideBarOpen ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <Assignment />
            </ListItemIcon>
            <ListItemText
              primary="Assigned to me"
              sx={{ opacity: sideBarOpen ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: sideBarOpen ? "initial" : "center",
              px: 2.5,
            }}
            selected={selectedTab === "unassigned"}
            onClick={() => {
              setSearchParams("selectedTab=unassigned", { replace: true });
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: sideBarOpen ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <Help />
            </ListItemIcon>
            <ListItemText
              primary="Unassigned"
              sx={{ opacity: sideBarOpen ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: sideBarOpen ? "initial" : "center",
              px: 2.5,
            }}
            selected={selectedTab === "unread"}
            onClick={() => {
              setSearchParams("selectedTab=unread", { replace: true });
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: sideBarOpen ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <VisibilityOff />
            </ListItemIcon>
            <ListItemText
              primary="Unread"
              sx={{ opacity: sideBarOpen ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </CustomDrawer>
  );
};

export default SideBar;
