import {
  Chip,
  IconButton, LinearProgress,
  List, ListItem,
  ListItemButton,
  ListItemText, Menu, MenuItem, Tooltip,
  Typography
} from "@mui/material";
import {Stack} from "@mui/system";
import {
  CheckCircle,
  Email,
  FilterTiltShift,
  MoreVert,
  Sms
} from "@mui/icons-material";
import {ContactTextComponent} from "../../helpers/contact/ContactTextComponent";
import moment from "moment-timezone";
import {ConversationItem} from "../ConversationsPage/ConversationsPanel";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {useCognitoUserInfo} from "../../hooks";
import {
  assignConversation,
  closeConversation,
  markConversationAsRead,
  markConversationAsUnread, openConversation
} from "../../helpers/conversation/conversation.helper";
import MarkunreadIcon from '@mui/icons-material/Markunread';
import DraftsIcon from '@mui/icons-material/Drafts';

interface ConversationItemComponentProps {
  conversation: ConversationItem
  selectedConversationId: string
  getAllFromBackendCallBack: Function
}

export const ConversationItemComponent = (
  {conversation, selectedConversationId, getAllFromBackendCallBack}: ConversationItemComponentProps
) => {
  const [actionMenuAnchor, setActionMenuAnchor] = useState<HTMLElement>();
  const [contextConversation, setContextConversation] =
    useState<ConversationItem>();
  const [enhancedTags, setEnhancedTags] = useState<string[]>([])
  const [showLoading, setShowLoading] = useState(false)
  const [stateConversation, setStateConversation] = useState(conversation)
  const [bgColor, setBgColor] = useState("rgb(240, 240, 240)")

  const cognitoUserinfo = useCognitoUserInfo();

  const navigate = useNavigate();

  // navigate to conversation details page
  const navigateToConversationDetails = (id: string): void => {
    navigate(`/conversations/${id}`)
  }

  // adjust color
  useEffect(() => {
    if (conversation.unread === true) {
      setBgColor("rgb(255,255,255)")
    }
  }, [conversation])

  // add some tags
  useEffect(() => {
    if (stateConversation) {
      console.log(`enhancing Tags...`)
      let updatedEnhancedTags: string[] = []
      updatedEnhancedTags = updatedEnhancedTags.concat(stateConversation.tags)
      let originalTagLength = enhancedTags.length
      if (stateConversation.conversationOpen) {
        updatedEnhancedTags.push(`Open`)
      } else {
        updatedEnhancedTags.push(`Closed`)
      }
      if (stateConversation.unread) {
        updatedEnhancedTags.push(`Unread`)
      }
      if (stateConversation.needsActionFromCompany) {
        updatedEnhancedTags.push(`Needs Action`)
      }
      if (stateConversation.employeeAssignedEmail) {
        updatedEnhancedTags.push(stateConversation.employeeAssignedEmail)
      }
      setEnhancedTags(updatedEnhancedTags)
    }
  }, [stateConversation])

  const assignToMe = async (email: string) => {
    await setShowLoading(true)
    await setActionMenuAnchor(undefined)
    let updateResp = await assignConversation({
      conversationSk: conversation.sk,
      employeeEmail: email,
    })
    await setStateConversation(updateResp)
    await setShowLoading(false)

  }

  const closeItem = async (conversationSk: string) => {
    await setShowLoading(true)
    await setActionMenuAnchor(undefined)
    let updateResp = await closeConversation(conversationSk)
    setStateConversation(updateResp)
    await getAllFromBackendCallBack()
    await setShowLoading(false)
  }

  const markAsRead = async (conversationSk: string) => {
    await setShowLoading(true)
    await setActionMenuAnchor(undefined)
    let updateResp = await markConversationAsRead(conversationSk)
    setStateConversation(updateResp)
    await getAllFromBackendCallBack()
    await setShowLoading(false)
  }

  const markAsUnread = async (conversationSk: string) => {
    await setShowLoading(true)
    await setActionMenuAnchor(undefined)
    let updateResp = await markConversationAsUnread(conversationSk)
    setStateConversation(updateResp)
    await getAllFromBackendCallBack()
    await setShowLoading(false)
  }

  const openItem = async (conversationSk: string) => {
    await setShowLoading(true)
    await setActionMenuAnchor(undefined)
    let updateResp = await openConversation(conversationSk)
    setStateConversation(updateResp)
    await getAllFromBackendCallBack()
    await setShowLoading(false)
  }

  return (
    <>
      <ListItem disablePadding sx={{backgroundColor: bgColor}}>
        <ListItemButton
          sx={{ mx: 1 }}
          disableGutters
          selected={stateConversation.sk === selectedConversationId}
          onClick={() => navigateToConversationDetails(stateConversation.sk)}
        >
          <List sx={{ minWidth: "100%" }}>
            <ListItemText
              secondaryTypographyProps={{ sx: { ml: 5.5 } }}
              primary={
                <Stack direction="row" justifyContent="space-between">
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                  >
                    <IconButton>
                      {stateConversation.unread ? (
                        <MarkunreadIcon fontSize="small" />
                      ) : (
                        <DraftsIcon fontSize="small" />
                      )}
                    </IconButton>
                    <Typography variant="subtitle1">
                      {stateConversation.unread &&
                        <strong>
                          <ContactTextComponent contactSk={stateConversation.contactSk} />
                        </strong>
                      }
                      {!stateConversation.unread &&
                        <ContactTextComponent contactSk={stateConversation.contactSk} />
                      }

                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                  >
                    <Tooltip
                      title={moment(
                        stateConversation.updatedAt
                      ).format("LLLL")}
                      arrow
                      followCursor
                      sx={{ "&:hover": { cursor: "pointer" } }}
                    >
                      <Typography component="span" variant="body2">
                        {moment(
                          stateConversation.updatedAt
                        ).fromNow()}
                      </Typography>
                    </Tooltip>
                    {stateConversation.customerContactMethod === "EMAIL" ? (
                      <Email fontSize="small" />
                    ) : (
                      <Sms fontSize="small" />
                    )}
                  </Stack>
                </Stack>
              }
              secondary={
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  component="span"
                  alignItems="center"
                >
                  {stateConversation.unread &&
                    <strong>
                    <Typography variant="h6" component="span">
                      {stateConversation.initialMessageBody.length > 150
                        ? stateConversation.initialMessageBody.substring(0, 150) +
                        " ..."
                        : stateConversation.initialMessageBody}
                      {showLoading &&
                        <LinearProgress/>
                      }
                    </Typography>
                    </strong>
                  }
                  {!stateConversation.unread &&
                    <Typography variant="body1" component="span">
                      {stateConversation.initialMessageBody.length > 150
                        ? stateConversation.initialMessageBody.substring(0, 150) +
                        " ..."
                        : stateConversation.initialMessageBody}
                      {showLoading &&
                        <LinearProgress/>
                      }
                    </Typography>
                  }

                  <IconButton
                    size="small"
                    id="action-menu-button"
                    onClick={(event) => {
                      event.stopPropagation();
                      setContextConversation(stateConversation);
                      setActionMenuAnchor(event.currentTarget);
                    }}
                  >
                    <MoreVert />
                  </IconButton>
                </Stack>
              }
            />
            <ListItemText>
              <Stack direction="row" sx={{ ml: 5 }} flexWrap="wrap">
                {enhancedTags &&
                  Object.entries(enhancedTags)?.map(
                    ([key, tag], index) => (
                      <Chip
                        key={index}
                        size="small"
                        label={tag}
                        sx={{ m: 0.5 }}
                      />
                    )
                  )}
              </Stack>
            </ListItemText>
          </List>
        </ListItemButton>
      </ListItem>
      <Menu
        id="action-menu"
        anchorEl={actionMenuAnchor}
        open={Boolean(actionMenuAnchor)}
        onClose={() => {
          setActionMenuAnchor(undefined);
          setContextConversation(undefined);
        }}
      >
        {stateConversation?.employeeAssignedEmail !==
          cognitoUserinfo?.email && cognitoUserinfo &&
          <MenuItem onClick={() => assignToMe(cognitoUserinfo.email)}>
            Assign to me
          </MenuItem>}
        {stateConversation?.unread ? (
          <MenuItem onClick={() => markAsRead(stateConversation.sk)}>Mark as read</MenuItem>
        ) : (
          <MenuItem onClick={() => markAsUnread(stateConversation.sk)}>Mark as unread</MenuItem>
        )}
        {stateConversation?.conversationOpen ? (
          <MenuItem onClick={() => closeItem(stateConversation.sk)}>Mark as closed</MenuItem>
        ) : (
          <MenuItem onClick={() => openItem(stateConversation.sk)}>Mark as open</MenuItem>
        )}
      </Menu>
    </>

  )
}

