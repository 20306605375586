import { useEffect, useState } from "react";
import {
  CheckCircle,
  Email,
  FilterTiltShift,
  MoreVert,
  Sms,
} from "@mui/icons-material";
import {
  Alert,
  Chip, Container,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import moment from "moment-timezone";
import { useQuery } from "react-query";

import { backendApiClient } from "../../../helpers/backendApiClient";
import useAppBarHeight from "../../../hooks/useAppBarHeight";
import { useCognitoUserInfo } from "../../../hooks";
import {useNavigate} from "react-router-dom";
import {
  ContactTextComponent
} from "../../../helpers/contact/ContactTextComponent";
import {
  ConversationItemComponent
} from "../../ConversationItem/ConversationItemComponent";

export interface ConversationItem {
  pk: string
  sk: string
  status: string
  conversationOpen: boolean
  needsActionFromCompany: boolean
  contactSk: string
  customerContactMethod: string
  tags: string[]
  unread: boolean
  createdAt: string
  updatedAt: string
  employeeAssignedEmail?: string
  initialMessageBody: string

  // contactEndpoint: string;
  // contactName: string;
  // lastMessage: string;
  // lastMessageTimestamp: string;

}

interface ConversationsPanelProps {
  filter: string | undefined;
  selectedConversationId: string | undefined;
  // setSelectedConversationId: Dispatch<SetStateAction<string | undefined>>;
}

const ConversationsPanel = ({
  filter,
  selectedConversationId,
  // setSelectedConversationId,
}: ConversationsPanelProps) => {
  const appBarHeight = useAppBarHeight();
  const cognitoUserinfo = useCognitoUserInfo();
  const [dbConversations, setDbConversations] = useState<ConversationItem[]>();
  const [conversations, setConversations] = useState<ConversationItem[]>([]);
  const [apiErrorMessage, setApiErrorMessage] = useState<string>();
  const [showApiError, setShowApiError] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState(false)

  useEffect(() => {
    if (dbConversations) {
      switch (filter) {
        case "open":
          setConversations(
            dbConversations.filter(
              (conversation: ConversationItem) => conversation.conversationOpen
            )
          );
          break;
        case "closed":
          setConversations(
            dbConversations.filter(
              (conversation: ConversationItem) => !conversation.conversationOpen
            )
          );
          break;
        case "assignedToMe":
          setConversations(
            dbConversations.filter(
              (conversation: ConversationItem) =>
                conversation.employeeAssignedEmail === cognitoUserinfo?.email
            )
          );
          break;
        case "unassigned":
          setConversations(
            dbConversations.filter(
              (conversation: ConversationItem) =>
                !conversation.employeeAssignedEmail
            )
          );
          break;
        case "unread":
          setConversations(
            dbConversations.filter(
              (conversation: ConversationItem) => conversation.unread
            )
          );
          break;
        default:
          setConversations(dbConversations);
      }
    } else {
      // get from backend
      getFromBackend().then(() => {})
    }
  }, [filter, dbConversations, cognitoUserinfo?.email]);

  // poll every 10 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      getFromBackend().then(() => {})
    }, 10000);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [])

  const getFromBackend = async () => {
    console.log(`getting from BE...`)
    await setShowLoading(true)
    let api = await backendApiClient()
    let resp = await api.get("/conversations")
    await setDbConversations(resp.data)
    await setShowLoading(false)
  }

  return (
    <Grid item xs={12} md={5} lg={3}>
      <Paper
        elevation={3}
        variant="elevation"
        sx={{ minHeight: `calc(100vh - ${appBarHeight}px)` }}
      >
        {showLoading &&
          <LinearProgress />
        }
        <List>
          <ListItem>
            <strong>{filter}</strong>
          </ListItem>
          {conversations.length > 0 ? (
            conversations?.map((conversation) => (
              <ConversationItemComponent
                conversation={conversation}
                selectedConversationId={selectedConversationId || ""}
                key={conversation.sk}
                getAllFromBackendCallBack={getFromBackend}
              />
            ))
          ) : (
            <Container>
              <h3>no conversations to show...</h3>
            </Container>
          )}
        </List>

      </Paper>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={showApiError}
        onClose={() => setShowApiError(false)}
      >
        <Alert severity="error" onClose={() => setShowApiError(false)}>
          {apiErrorMessage}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default ConversationsPanel;
