import {
  ConversationItem
} from "../../core/ConversationsPage/ConversationsPanel";
import {backendApiClient} from "../backendApiClient";

export interface assignConversationInput {
  conversationSk: string
  employeeEmail: string
}

export async function assignConversation(input: assignConversationInput): Promise<ConversationItem> {
  const axios = await backendApiClient()
  const updateResp = await axios.put(
    `/conversations/${input.conversationSk}`,
    {
      "employeeAssignedEmail": input.employeeEmail
    }
  )
  return Promise.resolve(updateResp.data)
}

export async function closeConversation(conversationSk: string): Promise<ConversationItem> {
  const axios = await backendApiClient()
  const updateResp = await axios.put(
    `/conversations/${conversationSk}`,
    {
      "status": "CLOSED",
      "conversationOpen": false,
    }
  )
  return Promise.resolve(updateResp.data)
}

export async function openConversation(conversationSk: string): Promise<ConversationItem> {
  const axios = await backendApiClient()
  const updateResp = await axios.put(
    `/conversations/${conversationSk}`,
    {
      "status": "WAITING_ON_KTW",
      "conversationOpen": true,
      "needsActionFromCompany": true,
    }
  )
  return Promise.resolve(updateResp.data)
}

export async function markConversationAsRead(conversationSk: string): Promise<ConversationItem> {
  const axios = await backendApiClient()
  const updateResp = await axios.put(
    `/conversations/${conversationSk}`,
    {
      "unread": false
    }
  )
  return Promise.resolve(updateResp.data)
}

export async function markConversationAsUnread(conversationSk: string): Promise<ConversationItem> {
  const axios = await backendApiClient()
  const updateResp = await axios.put(
    `/conversations/${conversationSk}`,
    {
      "unread": true
    }
  )
  return Promise.resolve(updateResp.data)
}
