import { useNavigate } from "react-router-dom";
import { Box, Button, Container, Typography, Stack } from "@mui/material";
import { Auth } from "aws-amplify";

import { useCognitoUserInfo } from "../../hooks";

const Home = () => {
  const cognitoUserInfo = useCognitoUserInfo();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        pt: 8,
        pb: 6,
      }}
    >
      <Container maxWidth="sm">
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          {process.env.REACT_APP_NAME}
        </Typography>
        <Typography
          variant="h5"
          align="center"
          color="text.secondary"
          paragraph
        >
          This project is managing conversations for KTW.
        </Typography>
        <Stack
          sx={{ pt: 4 }}
          direction="row"
          spacing={2}
          justifyContent="center"
        >
          {cognitoUserInfo ? (
            <>
              <Button
                variant="contained"
                onClick={() => navigate("/conversations")}
              >
                Conversations
              </Button>
              <Button variant="contained" onClick={() => navigate("/contacts")}>
                Contacts
              </Button>
            </>
          ) : (
            <Button variant="contained" onClick={() => Auth.federatedSignIn()}>
              Sign in to Access v.0.0.2
            </Button>
          )}
        </Stack>
      </Container>
    </Box>
  );
};

export default Home;
