import {
  Avatar,
  Box,
  Button,
  Card, CardActions,
  CardContent,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";

import { useCognitoUserInfo } from "../../hooks/";
import {useEffect, useState} from "react";
import {Auth} from "aws-amplify";
import {CognitoUser} from "@aws-amplify/auth";

const ProfilePage = (): JSX.Element => {
  const cognitoUserInfo = useCognitoUserInfo();
  const [cognitoUser, setCognitoUser] = useState<undefined | CognitoUser>(undefined)
  const [idToken, setIdToken] = useState<any | undefined>(undefined)
  const [accessToken, setAccessToken] = useState<any | undefined>(undefined)
  const [refreshToken, setRefreshToken] = useState<any | undefined>(undefined)
  const [tokenTitle, setTokenTitle] = useState('ID Token')
  const [activeToken, setActiveToken] = useState<any>(undefined)
  const [activeTokenString, setActiveTokenString] = useState<string>("")

  // set cognitoUser
  useEffect(() => {
    const asyncFunc = async () => {
      Auth.currentAuthenticatedUser()
        .then(async (cognitoUser: CognitoUser) => {
          await setCognitoUser(cognitoUser)
          let idToken = cognitoUser.getSignInUserSession()?.getIdToken().payload
          await setIdToken(idToken)
          await setActiveToken(idToken)
          await setAccessToken(
            cognitoUser.getSignInUserSession()?.getAccessToken().payload)
          await setRefreshToken(
            cognitoUser.getSignInUserSession()?.getRefreshToken().getToken())
        })
    }
    if (cognitoUser === undefined) {
      asyncFunc().then(() => {})
    }
  }, [])

  // set activeToken when tokenTitle changes
  useEffect(() => {
    if (tokenTitle === 'ID Token') {
      setActiveToken(idToken)
    }
    if (tokenTitle === 'Access Token') {
      setActiveToken(accessToken)
    }
    if (tokenTitle === 'Refresh Token') {
      setActiveToken(refreshToken)
    }
  }, [tokenTitle])

  // set activeTokenString
  useEffect(() => {
    if (cognitoUser) {
      if (tokenTitle === 'ID Token') {
        let idToken = cognitoUser.getSignInUserSession()?.getIdToken().getJwtToken()
        if (idToken) {
          setActiveTokenString(idToken)
        }
      }
      if (tokenTitle === 'Access Token') {
        let token = cognitoUser.getSignInUserSession()?.getAccessToken().getJwtToken()
        if (token) {
          setActiveTokenString(token)
        }
      }
      if (tokenTitle === 'Refresh Token') {
        let token = cognitoUser.getSignInUserSession()?.getRefreshToken().getToken()
        if (token) {
          setActiveTokenString(token)
        }
      }
    }

  }, [activeToken])

  return (
    <Box
      sx={{
        bgcolor: "background.",
        pt: 8,
        pb: 6,
      }}
    >
      <Container maxWidth="md">
        <Card variant="outlined">
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              My Account
            </Typography>
            <Grid
              container
              spacing={0}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={2}>
                <Avatar
                  src={cognitoUserInfo?.picture}
                  alt={cognitoUserInfo?.name}
                  sx={{ width: 100, height: 100, margin: "auto" }}
                  variant="square"
                />
              </Grid>
              <Grid item xs={10}>
                <Card variant="outlined">
                  <List
                    sx={{
                      width: "100%",
                      maxWidth: 360,
                      bgcolor: "background.paper",
                    }}
                  >
                    <ListItem>
                      <ListItemText
                        primary="Name"
                        secondary={cognitoUserInfo?.name}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Email"
                        secondary={cognitoUserInfo?.email}
                      />
                    </ListItem>
                    <ListItem>
                      <Button variant="contained">Update</Button>
                    </ListItem>
                  </List>
                </Card>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
      {process.env.REACT_APP_ENV !== 'prod' && cognitoUser &&
        <Container>
          <Card>
            <CardContent>
              <h3>{tokenTitle}</h3>
              <pre>{JSON.stringify(activeToken, null, 2)}</pre>
              <pre>{activeTokenString}</pre>
            </CardContent>
            <CardActions>
              <Button onClick={() => setTokenTitle('ID Token')}>
                ID Token
              </Button>
              <Button onClick={() => setTokenTitle('Access Token')}>
                Access Token
              </Button>
              <Button onClick={() => setTokenTitle('Refresh Token')}>
                Refresh Token
              </Button>
            </CardActions>
          </Card>
        </Container>
      }
    </Box>
  );
};

export default ProfilePage;
