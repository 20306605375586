import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import {
  ConversationMessage
} from "../helpers/ConversationMessage/conversation-message.interface";
import moment from "moment-timezone";
import {useEffect, useState} from "react";
import {
  generateAvatarInitialsHelper
} from "../helpers/generate-avatar-initials.helper";

interface MessagesProps {
  message: ConversationMessage
  // sentBy: string;
  // alignment: "left" | "right";
  // content: string;
  // time: string;
}

const Message = ({ message }: MessagesProps) => {
  const [alignment, setAlignment] = useState("left")
  const [avatarText, setAvatarText] = useState("AV")
  const [bgColorString, setBgColorString] = useState("rgba(0, 0, 0, 0.1)")

  useEffect(() => {
    if (message.sentBy.type === "EMPLOYEE") {
      setAlignment("right")
      setBgColorString("rgba(18, 173, 255, 0.2)")
    }
    if (message) {
      generateAvatarInitialsHelper(message.sentBy).then((x: string) => {
        setAvatarText(x)
      })
    }
  }, [message])

  return (
    <Grid
      container
      direction="row"
      item
      flexDirection={alignment === "left" ? "row" : "row-reverse"}
      alignItems="center"
    >
      <Card
        sx={{
          maxWidth: "60%",
          margin: 0.5,
          borderRadius: 5,
          background: bgColorString,
        }}
      >
        <CardContent sx={{ paddingY: 1 }}>
          <Typography variant="body2" align="left">
            {message.message}
          </Typography>
        </CardContent>
        <CardActions sx={{ paddingY: 1 }}>
          <Avatar sx={{ width: 24, height: 24, marginRight: 1 }}>
            {avatarText}
          </Avatar>
          <Typography
            variant="caption"
            sx={{
              color: "rgba(0, 0, 0, 0.5)",
            }}
          >
            {moment(message.timestamp).format("LLLL")}
          </Typography>
          <Typography
            variant="caption"
            sx={{
              color: "rgba(0, 0, 0, 0.5)",
            }}
          >
            {message.sentBy.type}
          </Typography>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default Message;
