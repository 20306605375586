import {ConversationMessage} from "./conversation-message.interface";
import {backendApiClient} from "../backendApiClient";

export async function postNewConversationMessage(
  conversationMessage: ConversationMessage
): Promise<ConversationMessage> {
  let newMessage: any = {...conversationMessage}
  delete newMessage.pk
  delete newMessage.sk
  const api = await backendApiClient()
  const postResp = await api.post("/conversations/" + conversationMessage.conversationSk + "/messages", newMessage)
  return Promise.resolve(postResp.data)
}
