import {
  AppBar,
  Box,
  Button,
  ButtonGroup,
  Container, IconButton,
  LinearProgress,
  Paper, TextField, Typography
} from "@mui/material";
import {KeyboardBackspace} from "@mui/icons-material";
import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {ConversationItem} from "../ConversationsPage/ConversationsPanel";
import {backendApiClient} from "../../helpers/backendApiClient";
import {
  ConversationMessage
} from "../../helpers/ConversationMessage/conversation-message.interface";
import {ContactTextComponent} from "../../helpers/contact/ContactTextComponent";
import Message from "../../common/Message";
import moment from "moment-timezone";
import Toolbar from '@mui/material/Toolbar';
import SendIcon from '@mui/icons-material/Send';
import MoreIcon from '@mui/icons-material/MoreVert';
import {
  postNewConversationMessage
} from "../../helpers/ConversationMessage/conversation-message.helper";
import {useCognitoUserInfo} from "../../hooks";
import {
  closeConversation,
  markConversationAsRead, markConversationAsUnread, openConversation
} from "../../helpers/conversation/conversation.helper";
import DeleteIcon from '@mui/icons-material/Delete';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';


const ConversationDetailsPage = (): JSX.Element => {
  const navigate = useNavigate()
  const [conversationFromBackend, setConversationFromBackend] = useState<ConversationItem | undefined>(undefined)
  const [messagesFromBe, setMessagesFromBe] = useState<ConversationMessage[]>([])
  const [showLoading, setShowLoading] = useState(false)
  const [replyText, setReplyText] = useState("")
  const [disableReplyField, setDisableReplyField] = useState(false)

  let { conversationId } = useParams();

  const cognitoUserinfo = useCognitoUserInfo();

  const bottomRef = useRef(null);

  useEffect(() => {
    const asyncFunc = async () => {
      await getFromBe()
    }
    if (conversationId) {
      asyncFunc().then(() => {})
    }
  }, [conversationId])

  const getFromBe = async () => {
    await setShowLoading(true)
    const api = await backendApiClient()
    let convoResp = await api.get("/conversations/" + conversationId)
    // mark as read if we need to
    if (convoResp.data.unread === true) {
      convoResp.data = await markConversationAsRead(convoResp.data.sk)
    }
    await setConversationFromBackend(convoResp.data)
    const messagesResp = await api.get("/conversations/" + conversationId + "/messages")
    await setMessagesFromBe(messagesResp.data)
    await setShowLoading(false)
  }

  // poll every 10 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      getFromBe().then(() => {})
    }, 10000);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [])

  const closeItem = async (conversationSk: string) => {
    await setShowLoading(true)
    let updateResp = await closeConversation(conversationSk)
    await setShowLoading(false)
    navigate(-1)
  }

  const handleReplyChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    await setReplyText(e.currentTarget.value)
  }

  const markAsUnread = async (conversationSk: string) => {
    await setShowLoading(true)
    let updateResp = await markConversationAsUnread(conversationSk)
    await setShowLoading(false)
    navigate(-1)
  }

  const handleReply = async (text: string) => {
    await setDisableReplyField(true)
    await setShowLoading(true)
    console.log(`text: ${text}`)
    const postResp = await postNewConversationMessage({
      pk: "conversation_message",
      sk: "tbd",
      message: text,
      messageMethod: "PORTAL",
      conversationSk: conversationId || "UNKNOWN", // conversation.sk
      sentBy: {
        endpoint: cognitoUserinfo?.email || 'UNKNOWN',
        type: "EMPLOYEE",
      },
      timestamp: new Date().toISOString(), // ISO Timestamp
      createdAt: new Date().toISOString(), // ISO Timestamp
      updatedAt: new Date().toISOString(), // ISO Timestamp
    })
    await setDisableReplyField(false)
    await setReplyText("")
    await setShowLoading(false)
    await getFromBe()
    scrollToBottom()
  }

  const openItem = async (conversationSk: string) => {
    await setShowLoading(true)
    let updateResp = await openConversation(conversationSk)
    await setShowLoading(false)
    await getFromBe()
    // navigate(-1)
  }

  const scrollToBottom = () => {
    // @ts-ignore
    bottomRef.current?.scrollIntoView({behavior: 'smooth'});
  }

  return (
    <>
      {conversationFromBackend && messagesFromBe &&
        <>
          <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" color={"transparent"}>
              <Toolbar>
                <Button
                  variant="outlined"
                  startIcon={<KeyboardBackspace />}
                  size={"small"}
                  onClick={() => navigate(-1)}
                >
                  Go Back
                </Button>
                &nbsp;&nbsp;&nbsp;
                {conversationId &&
                  <>
                    <Button
                      variant="outlined"
                      startIcon={<MarkunreadIcon/>}
                      size={"small"}
                      onClick={() => markAsUnread(conversationId || "UNKNOWN")}
                    >
                      Mark As Unread
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                  </>
                }

                {conversationFromBackend && conversationFromBackend.conversationOpen &&
                  <>
                    <Button
                      variant="outlined"
                      startIcon={<CloseIcon />}
                      size={"small"}
                      onClick={() => closeItem(conversationId || "UNKNOWN")}
                    >
                      Close
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                  </>
                }

                {conversationFromBackend && !conversationFromBackend.conversationOpen &&
                  <>
                    <Button
                      variant="outlined"
                      startIcon={<OpenInNewIcon />}
                      size={"small"}
                      onClick={() => openItem(conversationId || "UNKNOWN")}
                    >
                      Open
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                  </>
                }

                {/*<IconButton*/}
                {/*  size="large"*/}
                {/*  edge="start"*/}
                {/*  color="inherit"*/}
                {/*  aria-label="Go Back"*/}
                {/*  sx={{ mr: 2 }}*/}
                {/*  onClick={() => navigate(-1)}*/}
                {/*>*/}
                {/*  <KeyboardBackspace />*/}
                {/*</IconButton>*/}

              </Toolbar>
            </AppBar>
          </Box>
          {showLoading &&
            <Container style={{paddingTop: 5}}>
              <LinearProgress />
            </Container>
          }
          <Container>
            <>
              <h4>
                <ContactTextComponent
                  contactSk={conversationFromBackend.contactSk}
                />
                &nbsp;
                {conversationFromBackend.initialMessageBody}
              </h4>
              {messagesFromBe.map((message: ConversationMessage) => {
                return (
                  <Message
                    key={message.sk}
                    message={message}
                  />
                )
              })}
            </>
            <Container>
              &nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;
            </Container>
            <AppBar position="fixed" color={"transparent"} sx={{ top: 'auto', bottom: 0 }}>
              <Toolbar>
                <TextField
                  required
                  id="outlined-required"
                  label="reply to customer"
                  value={replyText}
                  onChange={handleReplyChange}
                  size={"small"}
                  sx={{minWidth: "75%"}}
                  disabled={disableReplyField}
                />
                <Box sx={{ flexGrow: 1 }} />
                <IconButton
                  color="inherit"
                  disabled={(replyText === "") || disableReplyField}
                  onClick={() => handleReply(replyText)}
                >
                  <SendIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
          </Container>
          <Container>
            <div ref={bottomRef}>
              <br />
              &nbsp;
              <br />
              &nbsp;
              <br />
            </div>
          </Container>
        </>
      }
    </>

  )
}

export default ConversationDetailsPage
