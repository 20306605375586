import {useEffect, useState} from "react";
import {backendApiClient} from "../backendApiClient";

interface ContactTextProps {
  contactSk: string
}

export const ContactTextComponent = ({contactSk}: ContactTextProps): JSX.Element => {
  const [contactName, setContactName] = useState('Loading...')

  useEffect(() => {
    const asyncFunc = async () => {
      if (contactName === 'Loading...') {
        let axios = await backendApiClient()
        let resp = await axios.get('/admin/query-dynamo?pk=contact')
        for (let i of resp.data) {
          if (i.sk === contactSk) {
            await setContactName(i.name)
          }
        }
      }
    }
    asyncFunc().then(() => {})

  }, [contactName])

  return (
    <>{contactName}</>
  )
}
